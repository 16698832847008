import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

import Dropdown from '@accrosoft-ltd/accro-multi-level-dropdown';

const Navbar = ({ props, websiteNavData }) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const ReturnChildNavItems = ({ WebsiteNavData }) => {
    return (
      <>
        {WebsiteNavData && WebsiteNavData.length > 0 ? (
          WebsiteNavData.map((item, i) => (
            <Dropdown
              menuClassName="dropdown-child-nav"
              wrapperClassName="dropdown-wrapper-nav"
              buttonClassName="dropdown-parentLink link-item"
              className="dropdown-child-nav"
              isActive={true}
              title={item.title}
              onClick={() =>
                navigate(
                  item.parentSlug ? item.parentSlug + item.slug : item.slug
                )
              }
            >
              <RenderChildLinks item={item} i={i} />
            </Dropdown>
          ))
        ) : (
          <li className="loading-nav"></li>
        )}
      </>
    );
  };

  const RenderChildLinks = ({ item }) => {
    return (
      <>
        {item.children && item.children.length > 0 && (
          <>
            {item.children.map((child, i) => (
              <RenderChildLinkHTML child={child} />
            ))}
          </>
        )}
      </>
    );
  };

  const RenderChildLinkHTML = ({ child, i }) => {
    return (
      <Dropdown.Item
        className="dropdown-item link"
        key={i}
        onClick={() =>
          navigate(
            child.parentSlug ? child.parentSlug + child.slug : child.slug
          )
        }
      >
        {child.children && <span className="fas fa-angle-right"></span>}
        <div>
          <span>{child.title}</span>
        </div>

        {child.children && child.children.length > 0 && (
          <Dropdown.Submenu
            menuClassName="dropdown-submenu"
            className="dropdown-submenu"
          >
            {child.children.map((childtwo, itwo) => (
              <React.Fragment key={i + '_' + itwo}>
                <RenderChildLinkHTML child={childtwo} i={itwo} />
              </React.Fragment>
            ))}
          </Dropdown.Submenu>
        )}
      </Dropdown.Item>
    );
  };

  return (
    <div className="navbar top-links__wrapper py-2 d-flex">
      <div className="top-header container d-flex justify-content-between top-links__wrapper__container">
        <div className="left-top">
          <p className="quicklink">
            <a href="tel:01423574410">
              <span className="fas fa-phone-square"></span>{' '}
              <span itemprop="telephone">01423 574410</span>
            </a>
          </p>

          <p className="quicklink">
            <a href="mailto:recruitment@rklt.co.uk">
              <span className="fas fa-envelope-square"></span>{' '}
              <span itemprop="email">recruitment@rklt.co.uk</span>
            </a>
          </p>

          <div className="socialicons">
            <a
              title="Twitter"
              href="https://www.twitter.com/RKLTPeople"
              target="_blank"
            >
              <span className="fab fa-twitter"></span>
            </a>
            <a
              title="Facebook"
              href="https://www.facebook.com/Red-Kite-Learning-Trust-People-105544608925754"
              target="_blank"
            >
              <span className="fab fa-facebook-f"></span>
            </a>
            <a
              title="Instagram"
              href="https://www.instagram.com/rkltpeople"
              target="_blank"
            >
              <span className="fab fa-instagram"></span>
            </a>
            <a
              title="LinkedIn"
              href="https://www.linkedin.com/company/red-kite-learning-trust"
              target="_blank"
            >
              <span className="fab fa-linkedin-in"></span>
            </a>
          </div>
        </div>

        <div className="right-top">
          <p class="quicklink">
            <a href="/accessibility" title="accessibility">
              <span class="fas fa-universal-access"></span> Accessibility
            </a>
          </p>
          <div id="google_translate_element"></div>
        </div>
      </div>
      <div className="bottom-header container d-flex justify-content-between top-links__wrapper__container">
        {props.siteConfig.companyCareersSite ? (
          <a href={`https://www.rklt.co.uk/careers/`} className="navbar-brand logo d-flex">
            <img src="/logo.svg" alt="red kite learning trust" />
            {/* {props.smartfeedCompanyData &&
              props.smartfeedCompanyData.CompanyName &&
              props.smartfeedCompanyData.Logo && (
                <img
                  loading="lazy"
                  src={props.smartfeedCompanyData.Logo}
                  className="logo"
                  alt={props.smartfeedCompanyData.CompanyName}
                />
              )} */}
          </a>
        ) : (
          <a href={`/${props.apiKey}/`} className="navbar-brand">
            {props.smartfeedCompanyData &&
            props.smartfeedCompanyData.CompanyName &&
            props.smartfeedCompanyData.Logo ? (
              <img
                loading="lazy"
                src={props.smartfeedCompanyData.Logo}
                className="logo"
                alt={props.smartfeedCompanyData.CompanyName}
              />
            ) : (
              <img
                loading="lazy"
                src="https://logos.vacancy-filler.co.uk/Custom%20Test/SmartFeedDemo.svg"
                className="logo"
                alt="Vacancy Filler"
              />
            )}
          </a>
        )}
        <div className="menu desktop">
          <ul className="navigation">
            <li className="parent">
              <div className="link-item">
                <span>About RKLT</span>
              </div>
              <ul className="children-nav-links">
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/our-schools/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span> Our Schools
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/our-trust/our-mission-values-and-goals/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span> Mission,
                      Values and Goals
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/teaching-school-hub/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span> Teaching
                      School Hub
                    </a>
                  </div>
                </li>
              </ul>
            </li>
            <li className="parent">
              <div className="link-item">
                <span>Join our Team</span>
              </div>
              <ul className="children-nav-links">
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/careers/join-our-team/rkltpeople/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span>RKLT People
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/careers/join-our-team/benefits-rewards/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span>Benefits and
                      Rewards
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/careers/join-our-team/apprenticeships/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span>Apprenticeships
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a
                      href="https://www.rklt.co.uk/careers/join-our-team/school-experience-programme/"
                      target="_blank"
                    >
                      <span className="fa-solid fa-minus"></span>School
                      Experience Programme
                    </a>
                  </div>
                </li>
              </ul>
            </li>
            <li className="parent">
              <div className="link-item">
                <span>Recruitment Guidance & Info</span>
              </div>
              <ul className="children-nav-links">
                <li>
                  <div className="link-item">
                    <a href="/recruitment-guidance-info/recruitment-guidelines/">
                      <span className="fa-solid fa-minus"></span>Recruitment
                      Guidelines
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <a href="/recruitment-guidance-info/rehabilitation-of-offenders/">
                      <span className="fa-solid fa-minus"></span>
                      Rehabilitation of Offenders
                    </a>
                  </div>
                </li>
              </ul>
            </li>

            <li className="parent">
              <div className="link-item">
                <span>Our Vacancies</span>
              </div>

              <ul className="children-nav-links">
                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <a
                        href={
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }
                      >
                        <span class="fa-solid fa-minus"></span>
                        Our Vacancies
                      </a>
                    ) : (
                      <a
                        href={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }`}
                      >
                        <span class="fa-solid fa-minus"></span>
                        Our Vacancies
                      </a>
                    )}
                  </div>
                </li>

                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <Link
                        to={`${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }saved/`}
                      >
                        <span class="fa-solid fa-minus"></span>
                        Saved Jobs
                      </Link>
                    ) : (
                      <Link
                        to={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }saved/`}
                      >
                        <span class="fa-solid fa-minus"></span>
                        Saved Jobs
                      </Link>
                    )}
                  </div>
                </li>
                {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
                  <li>
                    <div className="link-item">
                      <a
                        href={props.appGatewayURL.ApplicantGateway}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span class="fa-solid fa-minus"></span>
                        Applicant Gateway
                      </a>
                    </div>
                  </li>
                )}

                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <Link
                        className=""
                        to={
                          props.useGroupVacsLink === true
                            ? '/group-job-alerts/'
                            : `/job-alerts/`
                        }
                      >
                        <span class="fa-solid fa-minus"></span>
                        Get Job Alerts
                      </Link>
                    ) : (
                      <Link
                        className=""
                        to={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-job-alerts/'
                            : `/job-alerts/`
                        }`}
                      >
                        <span class="fa-solid fa-minus"></span>
                        Get Job Alerts
                      </Link>
                    )}
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="nav__hamburger_wrap">
          <div
            className={`nav_hamburger_wrap__inner ${
              showMobileNav === true ? 'toggled' : ''
            }`}
          >
            <div
              className="nav_hamburger_icon btn p-3"
              onClick={() => setShowMobileNav(!showMobileNav)}
              aria-hidden="true"
            >
              <span className="main-menu">Menu</span>
              {!showMobileNav ? (
                <span className="fas fa-bars"></span>
              ) : (
                <span className="fas fa-times"></span>
              )}
            </div>
            <div className="nav_hamburger_wrap">
              <ul className="navigation">
                {props.siteConfig.showWebsiteNav && websiteNavData && (
                  <ReturnChildNavItems WebsiteNavData={websiteNavData} />
                )}
                <li>
                  <div className="link-item">
                    <span>About RKLT</span>
                  </div>
                  <ul className="children-nav-links">
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/our-schools/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>Our Schools
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/our-trust/our-mission-values-and-goals/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>Mission,
                          Values and Goals
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/teaching-school-hub/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>Teaching
                          School Hub
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="link-item">
                    <span>Join our Team</span>
                  </div>
                  <ul className="children-nav-links">
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/careers/join-our-team/rkltpeople/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>RKLT People
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/careers/join-our-team/benefits-rewards/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>Benefits
                          and Rewards
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/careers/join-our-team/apprenticeships/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>
                          Apprenticeships
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <a
                          href="https://www.rklt.co.uk/careers/join-our-team/school-experience-programme/"
                          target="_blank"
                        >
                          <span className="fa-solid fa-minus"></span>School
                          Experience Programme
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="link-item">
                    <span>Recruitment Guidance & Info</span>
                  </div>
                  <ul className="children-nav-links">
                    <li>
                      <div className="link-item">
                        <a href="/recruitment-guidance-info/recruitment-guidelines/">
                          <span className="fa-solid fa-minus"></span>Recruitment
                          Guidelines
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="link-item">
                        <a href="/recruitment-guidance-info/rehabilitation-of-offenders/">
                          <span className="fa-solid fa-minus"></span>
                          Rehabilitation of Offenders
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <a
                        href={
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }
                      >
                        Our Vacancies
                      </a>
                    ) : (
                      <a
                        href={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }`}
                      >
                        Our Vacancies
                      </a>
                    )}
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <Link
                        to={`${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }saved/`}
                      >
                        Saved Jobs
                      </Link>
                    ) : (
                      <Link
                        to={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-vacancies/'
                            : `/vacancies/`
                        }saved/`}
                      >
                        Saved Jobs
                      </Link>
                    )}
                  </div>
                </li>
                {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
                  <li>
                    <div className="link-item">
                      <a
                        href={props.appGatewayURL.ApplicantGateway}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Applicant Gateway
                      </a>
                    </div>
                  </li>
                )}

                <li>
                  <div className="link-item">
                    {props.siteConfig.companyCareersSite ? (
                      <Link
                        className="nav-action-sm"
                        to={
                          props.useGroupVacsLink === true
                            ? '/group-job-alerts/'
                            : `/job-alerts/`
                        }
                      >
                        Get Job Alerts
                      </Link>
                    ) : (
                      <Link
                        className="nav-action-sm"
                        to={`/${props.apiKey}${
                          props.useGroupVacsLink === true
                            ? '/group-job-alerts/'
                            : `/job-alerts/`
                        }`}
                      >
                        Get Job Alerts
                      </Link>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
